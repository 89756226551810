import React from 'react'

import * as styles from './recipe-container.module.scss'

import RecipeDescription from '../RecipeDescription/RecipeDescription'
import RecipeInformation from '../RecipeInformation/RecipeInformation'
import { useSiteDictionary } from '../../../hooks'

const RecipeContainer = ({
    data
}) => {
    const {
        method,
        ingredients,
        servings,
        preparationTime
    } = data
    const {
        thingsNecessary,
        methodText
    } = useSiteDictionary()
    return (
        <div className={`${styles.container} triangle`}>
            <RecipeInformation
                servings={servings}
                time={preparationTime}
            />
            <RecipeDescription
                method={method}
                ingredients={ingredients}
                methodTitle={thingsNecessary}
                ingredientsTitle={methodText}
            />
        </div>
    )
}

export default RecipeContainer