import React from 'react'
import * as styles from './heading-level.module.scss';

const HeadingLevel = ({ headingLevel, title = '', extraClass = '', subTitle = "", subTitleClass = "" }) => {
    if (!title) return null
    switch (headingLevel) {
        case "1":
            return (
                <>
                    <h1 className={`${styles.heading1} ${extraClass}`}>
                        {title.split('/n').map((phrase, index) => (
                            <span className={styles.headingSpan} key={index}>{phrase}</span>
                        ))}
                    </h1>
                    {
                        subTitle && <p className={subTitleClass}>{subTitle}</p>
                    }
                </>

            )
        case "2":
            return (
                <>
                    <h2 className={`${styles.heading2} ${extraClass}`}>
                        {title.split('/n').map((phrase, index) => (
                            <span className={styles.headingSpan} key={index}>{phrase}</span>
                        ))}
                    </h2>
                    {
                        subTitle && <p className={subTitleClass}>{subTitle}</p>
                    }
                </>

            )
        case "3":
            return (
                <>
                    <h3 className={`${styles.heading3} ${extraClass}`}>
                        {title.split('/n').map((phrase, index) => (
                            <span className={styles.headingSpan} key={index}>{phrase}</span>
                        ))}
                    </h3>
                    {
                        subTitle && <p className={subTitleClass}>{subTitle}</p>
                    }
                </>

            )
        case "4":
            return (
                <>
                    <h4 className={`${styles.heading4} ${extraClass}`}>
                        {title.split('/n').map((phrase, index) => (
                            <span className={styles.headingSpan} key={index}>{phrase}</span>
                        ))}
                    </h4>
                    {
                        subTitle && <p className={subTitleClass}>{subTitle}</p>
                    }
                </>

            )
        default:
            return (
                <>
                    <p className={`${styles.heading5} ${extraClass}`}>
                        {title.split('/n').map((phrase, index) => (
                            <span className={styles.headingSpan} key={index}>{phrase}</span>
                        ))}
                    </p>
                    {
                        subTitle && <p className={subTitleClass}>{subTitle}</p>
                    }
                </>

            )
    }
}

export default HeadingLevel