// extracted by mini-css-extract-plugin
export var container = "recipe-collection-module--container--byfFL";
export var grid = "recipe-collection-module--grid--v1t1D";
export var gridContainer = "recipe-collection-module--grid-container--FyxG+";
export var gridItem = "recipe-collection-module--grid-item--w0kA7";
export var gridTitle = "recipe-collection-module--grid-title--tu7T-";
export var nextIcon = "recipe-collection-module--next-icon--GC7hu";
export var prevIcon = "recipe-collection-module--prev-icon--FZKM-";
export var swiperContainer = "recipe-collection-module--swiper-container--d8HpV";
export var titleContainer = "recipe-collection-module--title-container--6f4MP";
export var titleHeading = "recipe-collection-module--title-heading--8XU9g";
export var titleSubheading = "recipe-collection-module--title-subheading--mHshc";
export var top = "recipe-collection-module--top--GJBtf";