import React from 'react'
import { useSiteDictionary } from '../../../hooks'
import * as styles from './recipe-information.module.scss'

const RecipeInformation = ({
    servings,
    time
}) => {
    const {
        preparationTime,
        minutesUnits,
        servingsUnits
    } = useSiteDictionary()
    return (
        <div className={`container container-md ${styles.infoContainer}`}>
            <div className={`container container-sm ${styles.container}`}>
                <div>
                    <p className={`${styles.title}`}>{preparationTime}</p>
                    <p className={`${styles.desc}`}>{time}{minutesUnits}</p>
                </div>
                <div>
                    <p className={`${styles.title}`}>分量</p>
                    <p className={`${styles.desc}`}>{servings}{servingsUnits}</p>
                </div>
            </div>
        </div>
    )
}

export default RecipeInformation