import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo';

import RecipeContainer from '../components/Recipe/RecipeContainer/RecipeContainer';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import RecipeCollection from '../components/sections/RecipeCollection/RecipeCollection';
import { useSiteDictionary } from '../hooks';
import SectionBreadCrumbs from '../components/sections/SectionBreadCrumbs/SectionBreadCrumbs';

const Recipe = ({
    pageContext,
    location,
    data: {
        contentfulPageRecipe: {
            seoTitle,
            slug,
            seoDescription,
            pageTitle,
            seoImage,
            heroImage,
            breadCrumbs,
            ...restData
        },
        allContentfulPageRecipe: {
            nodes
        }
    }
}) => {
    const { recipeSuggestionsTitle } = useSiteDictionary()
    const [recommendedRecipes, setRecommendedRecipes] = useState([])
    useEffect(() => {
        const slugArray = slug.split('/')
        if (slugArray.length > 1) {
            const array = nodes.filter(({ slug }) => {
                if (slugArray[1]) {
                    if (slug.includes(slugArray[1])) return true
                }
                else return false
            })
            setRecommendedRecipes(array)
        }
    }, [])
    return (
        <div className='content'>
            <Seo
                title={seoTitle}
                slug={slug}
                description={seoDescription?.seoDescription}
                lang={pageContext.node_locale}
                dynamicSeoImage={seoImage ? seoImage : null}
            />
            {
                heroImage
                    ? <HeroComponent data={heroImage} index={0} recipe={true} />
                    : <></>
            }
            {
                breadCrumbs
                    ? <SectionBreadCrumbs title={pageTitle.split('/n').join('')} data={breadCrumbs} />
                    : <></>
            }
            <RecipeContainer data={restData} />
            <RecipeCollection data={{
                title: recipeSuggestionsTitle,
                items: recommendedRecipes
            }} />
        </div>
    )
}

export const RecipeQuery = graphql`
    query RecipeQuery( $id: String!, $contentful_id: String! ){
        contentfulPageRecipe(id: {eq: $id}){
            seoTitle
            slug
            seoDescription {
                seoDescription
            }
            pageTitle: thumbnailTitle
            method {
                raw
            }
            ingredients {
                raw
            }
            heroImage {
                ...componentHero
            }
            breadCrumbs {
                ...sectionsBreadcrumbs
            }
            servings
            preparationTime
            seoImage {
                file {
                    url
                }
            }
        }
        allContentfulPageRecipe(filter: {id: {ne: ""}, contentful_id: {ne: $contentful_id}, slug: {ne: null}, thumbnailTitle: {ne: null}, thumbnailImage: {file: {url: {ne: null}}}}){
            nodes {
                id
                slug
                pageTitle: thumbnailTitle
                thumbnailImage {
                    gatsbyImageData
                }
            }
        }
    }
`

export default Recipe